import Image from "next/image";
import Section4Image1 from "/public/assets/mobile/images/pic/img_main_section4_1.webp";
import Section4Image2 from "/public/assets/mobile/images/pic/img_main_section4_2.webp";
import { 링크 } from "./데이터/링크";
import { 무료체험기간 } from "./데이터/무료체험기간";
import { useTracker } from "./util/Tracker";
import { useContext } from "react";
import { GrowthBookCTX, getValue } from "./util/GrowthBookCTX";
import { pipe } from "@mobily/ts-belt";
import style from "./랜딩_섹션4_Mobile.module.css";
import { asx } from "./util/applyStyle";
import { useEffect, useRef, useState } from "react";

const as = asx(style);
export const 랜딩_섹션4_Mobile = () => {
  const featureFlag = useContext(GrowthBookCTX);
  const simplifiedRegister = pipe(featureFlag, getValue("simplified-register"));

  const tracker = useTracker();
  const ref = useRef<HTMLDivElement>(null);
  const [isTracked, setIsTracked] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isTracked) {
            tracker.trackingScroll({
              depth1: "HOME",
              depth2: "업종별서비스",
            });
            setIsTracked(true); 
          }
        });
      },
      {
        rootMargin: "0px", 
        threshold: 0.2, 
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [isTracked, tracker]);

  return (
    <div className={as("section section4")} ref={ref}>
      <h3>업종별 마켓봄 서비스</h3>
      <p className={as("desc")}>
        마켓봄은 가입비, 설치비, 세팅비가 모두 무료입니다.
      </p>
      <div className={as("white-box")}>
        <div className={as("row")}>
          <span className={as("num open-sans")}>01</span>
          <dl>
            <dt>
              유통사
              <br />
              프랜차이즈 본사, 물류사 등
              <Image src={Section4Image1} alt="" width={200} height={65} />
            </dt>
            <dd>결제없이 {`${무료체험기간.마켓봄}`} 무료이용</dd>
          </dl>
          <div className={as("btn-wrap")}>
            <a
              href={
                simplifiedRegister
                  ? 링크.마켓봄_간편가입_실험_링크_모바일
                  : 링크.마켓봄_간편가입_모바일
              }
              className={as("btn-start")}
              onClick={() =>
                tracker.trackingClick({
                  depth1: "INFO",
                  depth2: "회원가입",
                  depth3: "마켓봄",
                  indicator: "회원가입",
                })
              }
            >
              시작하기
            </a>
          </div>
        </div>
        <div className={as("row")}>
          <p>
            세상의 모든 수발주를 쉽고 간편하게
            <br />
            실시간으로 처리
          </p>
          <div className={as("btn-wrap")}>
            <a
              href={링크.마켓봄_자세히보기}
              className={as("btn-detail")}
              onClick={() =>
                tracker.trackingClick({
                  depth1: "INFO",
                  depth2: "마켓봄 자세히보기",
                })
              }
            >
              <span className="fc1">마켓봄</span> 자세히 보기
            </a>
          </div>
        </div>
      </div>
      <div className={as("white-box")}>
        <div className={as("row")}>
          <span className={as("num open-sans")}>02</span>
          <dl>
            <dt>
              중도매인
              <br />
              상회, 중상업자 등
              <Image src={Section4Image2} alt="" width={200} height={65} />
            </dt>
            <dd>도입 문의 시 모든 컨설팅 무료</dd>
          </dl>
          <div className={as("btn-wrap")}>
            <a
              href={링크.마켓봄도매_회원가입_모바일}
              className={as("btn-start")}
              onClick={() =>
                tracker.trackingClick({
                  depth1: "INFO",
                  depth2: "회원가입",
                  depth3: "마켓봄 도매",
                })
              }
            >
              시작하기
            </a>
          </div>
        </div>
        <div className={as("row")}>
          <p>
            가장 저렴하고 쉽고 간편한
            <br />
            판매 관리 프로그램
          </p>
          <div className={as("btn-wrap")}>
            <a
              href={링크.마켓봄도매_자세히보기}
              className={as("btn-detail")}
              onClick={() =>
                tracker.trackingClick({
                  depth1: "INFO",
                  depth2: "마켓봄 도매 자세히보기",
                })
              }
            >
              <span className="fc1">마켓봄 도매</span> 자세히 보기
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
