import Section1Image from "/public/assets/mobile/images/pic/img_main_section1.webp";
import styles from "./랜딩_섹션1_Mobile.module.css";
import { asx } from "./util/applyStyle";
const as = asx(styles);

export const 랜딩_섹션1_Mobile = () => {
  return (
    <>
      <div
        className={as("section section1")}
        style={{
          background: `url(${Section1Image.src}) no-repeat center bottom`,
          backgroundSize: "100% auto",
        }}
      >
        <p>
          유통관리가 편해지는
          <br />
          수발주 마켓봄
        </p>
      </div>
      <div style={{ height: "32px" }} className={"spacer"} />
    </>
  );
};
