import {useState, useEffect, useRef, } from "react";
import Image from "next/image";
import { 비디오_Mobile } from "./비디오_Mobile";
import { 링크 } from "./데이터/링크";
import { useTracker } from "./util/Tracker";
import Slider, { type Settings } from "react-slick";

import 유투브0 from "/public/assets/mobile/images/pic/youtube_thumbnail0.webp";
import 유투브1 from "/public/assets/mobile/images/pic/youtube_thumbnail1.webp";
import 유투브2 from "/public/assets/mobile/images/pic/youtube_thumbnail2.webp";
import 유투브3 from "/public/assets/mobile/images/pic/youtube_thumbnail3.webp";
import 유투브4 from "/public/assets/mobile/images/pic/youtube_thumbnail4.webp";

import { asx } from "./util/applyStyle";
import styles from "./랜딩_섹션2_Mobile.module.css";

const as = asx(styles);

export const 랜딩_섹션2_Mobile = () => {  
  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "50px",
  };
  const 유투브_관련_데이터 = [{
    메인화면:유투브0,
    영상링크:링크.랜딩_영상[0],
    title:"마켓봄 성공 사례 인터뷰",
    trackerDepth1:"HOME",
    trackerDepth2:"마켓봄 소개 영상",
    trackerDepth3:"플레이1",
  },
  {
    메인화면:유투브1,
    영상링크:링크.랜딩_영상[1],
    title:"마켓봄 성공 사례 인터뷰",
    trackerDepth1:"HOME",
    trackerDepth2:"마켓봄 소개 영상",
    trackerDepth3:"플레이2",
  },
  {
    메인화면:유투브2,
    영상링크:링크.랜딩_영상[2],
    title:"마켓봄 성공 사례 인터뷰",
    trackerDepth1:"HOME",
    trackerDepth2:"마켓봄 소개 영상",
    trackerDepth3:"플레이3",
  },
  {
    메인화면:유투브3,
    영상링크:링크.랜딩_영상[3],
    title:"마켓봄 성공 사례 인터뷰",
    trackerDepth1:"HOME",
    trackerDepth2:"마켓봄 소개 영상",
    trackerDepth3:"플레이4",
  },
  {
    메인화면:유투브4,
    영상링크:링크.랜딩_영상[4],
    title:"마켓봄 성공 사례 인터뷰",
    trackerDepth1:"HOME",
    trackerDepth2:"마켓봄 소개 영상",
    trackerDepth3:"플레이5",
  }];

  const tracker = useTracker();
  const ref = useRef<HTMLDivElement>(null);
  const [isTracked, setIsTracked] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // 요소가 뷰포트에 들어오는 경우에만 트래킹
          if (entry.isIntersecting && !isTracked) {
            tracker.trackingScroll({
              depth1: "HOME",
              depth2: "동영상",
            });
            setIsTracked(true); // 요소가 한 번 트래킹되면 다시 트래킹되지 않도록 상태를 변경
          }
        });
      },
      {
        rootMargin: "0px", // 필요에 따라 rootMargin을 조정할 수 있습니다.
        threshold: 1, // 요소가 10% 보일 때 콜백이 실행되도록 threshold 조정
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [isTracked, tracker]); // 의존성 배열에 isTracked와 tracker를 추가
  
  return (
    <div className="section section2" ref={ref}>
      <dl>
        <dt>식자재 유통은 마켓봄</dt>
        <dd>
          이제 식자재 유통도 <strong className="bold">온라인 시대!</strong>
          <br />
          언제 어디서나 쉽고 편리한 마켓봄으로
          <br />
          <div className="highlight">
            <p>
              수발주 효율
              <span className="open-sans">
                UP<em style={{ marginRight: "8px" }}>!</em>
              </span>
              {"매출도 "}
              <span className="open-sans">
                UP<em style={{ marginRight: "8px" }}>!</em>
              </span>
              미수금
              <span className="open-sans">
                DOWN<em style={{ marginRight: "8px" }}>!</em>
              </span>
            </p>
          </div>
        </dd>
      </dl>
      <div className="pic">
        <Slider className={as("youtube-wrap") + " slider"} {...settings}>
          {유투브_관련_데이터.map((item) => (
            <div className={"item"} key={item.title}>
              {item.영상링크 && (
                <비디오_Mobile title={"마켓봄 성공 사례 인터뷰"} src={item.영상링크 ?? 링크.랜딩_영상[0]}>
                  <Image
                    style={{ position:"relative"}}
                    src={item.메인화면}
                    alt={item.title}
                    width={260}
                    height={145}
                    onClick={() => {
                      tracker.trackingClick({
                        depth1: item.trackerDepth1,
                        depth2: item.trackerDepth2,
                        depth3: item.trackerDepth3,
                      });
                    }}
                    priority
                  />
                </비디오_Mobile>
              )}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
