import Link from "next/link";
import { CSR } from "./CSR";
import Slider, { type Settings } from "react-slick";
import {
  type 마켓봄_업데이트_소식_카드_정보,
  마켓봄_업데이트_소식_카드_정보_목록,
} from "./데이터/마켓봄_업데이트_소식";
import { 링크 } from "./데이터/링크";
import { asx } from "./util/applyStyle";
import style from "./랜딩_섹션8_Mobile.module.css";
const as = asx(style);

const 마켓봄_업데이트_소식_카드 = ({
  제목,
  날짜,
  설명,
  링크,
}: 마켓봄_업데이트_소식_카드_정보) => {
  return (
    <div className={"item"}>
      <h4 className={"title"}>{제목}</h4>
      <span className={"date"}>{날짜}</span>
      <p className={"desc"} style={{ wordBreak: "keep-all" }}>
        {설명}
      </p>
      <Link href={링크} className={"btn-detail"} target="_blank">
        자세히 보기
      </Link>
    </div>
  );
};

export const 마켓봄_업데이트_소식_Mobile = () => {
  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <CSR>
      <div className={as("section section8") + " section8"}>
        <h3>마켓봄 업데이트와 주요 소식이에요.</h3>
        <div className={as("slide-wrap")}>
          <Slider className={as("notice-wrap") + " slider"} {...settings}>
            {마켓봄_업데이트_소식_카드_정보_목록.flat().map((카드, i) => (
              <마켓봄_업데이트_소식_카드 {...카드} key={`업데이트_정보_${i}`} />
            ))}
          </Slider>
        </div>

        <div className={as("btn-wrap")}>
          <a href={링크.마켓봄_이용가이드} className={as("btn-usage-guide")}>
            <span className={"fc1"}>이용가이드</span> 바로가기
          </a>
        </div>
      </div>
    </CSR>
  );
};
