import { type RefObject, useEffect, useMemo, useState } from "react";

export default function useOnScreen(ref: RefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry!.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    if (!ref.current) return;
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [observer, ref]);

  return isIntersecting;
}

export function useFullOnScreen(ref: RefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => {
          console.log(entry?.isIntersecting, entry?.boundingClientRect?.top);
          return setIntersecting(
            ((entry?.isIntersecting ?? false) &&
              (entry?.boundingClientRect?.top ?? 100) <= 0) ??
              false
          );
        },
        { threshold: 0.1 }
      ),
    []
  );

  useEffect(() => {
    if (!ref.current) return;
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [observer, ref]);

  return isIntersecting;
}

export const useOnTopOfViewport = (ref: RefObject<HTMLElement>) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const listener = () => {
      setIntersecting(window.scrollY < (ref.current?.offsetTop ?? 99999));
    };

    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, [ref]);
  return isIntersecting;
};
