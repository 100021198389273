import { pipe } from "@mobily/ts-belt";
import { useEffect, useState } from "react";

function getEasedOutProgressRate(ratio: number) {
  return 1 - Math.pow(1 - ratio, 3);
}

const BROWSER_FPS = 60;

export interface UseCountAnimationProps {
  startNumber?: number; // count 시작 숫자
  endNumber: number; // count 종료 숫자
  durationMS: number; // 애니메이션 시간
  fpsReductionFactor?: number; // fps를 n배 줄이고 싶을 때의 n
  canStart?: boolean; // 애니메이션을 시작할 타이밍 (ex: 특정 영역이 화면이 보이기 시작할 때)
}
export const useCountAnimation = (props: UseCountAnimationProps) => {
  const {
    startNumber = 0,
    endNumber,
    durationMS,
    fpsReductionFactor = 1,
    canStart = true,
  } = props;

  const fps = BROWSER_FPS / fpsReductionFactor;

  const [count, setCount] = useState<number>(startNumber); // 변화할 숫자

  useEffect(() => {
    if (!canStart) return;
    let currentFrame = 0; // 만들어진 프레임 개수
    let lastRafExecutionTimestamp = 0; // 마지막으로 숫자를 카운팅했던 timestamp
    let rafId: number = 0; // rAF ID

    const handleCount = (currentTimestamp: number) => {
      // 원하는 주기가 아직 돌아오지 않았으면 숫자 카운팅을 skip 한다.
      if (
        Math.round(currentTimestamp - lastRafExecutionTimestamp) <
        Math.round(1000 / fps)
      ) {
        rafId = requestAnimationFrame(handleCount);
        return;
      }

      // fps에 따라 duration 동안 만들어질 총 프레임 개수
      const totalFrames = Math.ceil(durationMS / (1000 / fps));
      // ease out 효과가 적용된 카운팅 진행률
      const progressRate = getEasedOutProgressRate(currentFrame / totalFrames);
      // 1프레임마다 count를 증가시킨다.
      setCount(
        Math.floor(startNumber + (endNumber - startNumber) * progressRate)
      );
      currentFrame++;
      lastRafExecutionTimestamp = currentTimestamp;

      // 카운팅이 끝났으면 rAF 실행을 종료한다.
      if (progressRate === 1) {
        cancelAnimationFrame(rafId);
        return;
      }
      // rAF를 재실행하여 카운팅을 이어 나간다.
      rafId = requestAnimationFrame(handleCount);
    };

    // 첫 rAF 실행
    rafId = requestAnimationFrame(handleCount);
    return () => cancelAnimationFrame(rafId);
  }, [canStart, durationMS, endNumber, fps, startNumber]);

  return pipe(count, Intl.NumberFormat("ko-KR").format);
};
