import { type PropsWithChildren, useState } from "react";
import { CSR } from "./CSR";
import { 포탈 } from "./포탈";
import 닫기_아이콘 from "/public/assets/pc/images/btn/btn_close1.webp";

type VideoModalProps = {
  title: string;
  src: string;
  show: boolean;
  close: () => void;
};
export type VideoProps = {
  title: string;
  src: string;
};

const 비디오_모달_Desktop = ({ src, title, close, show }: VideoModalProps) => {
  const [innerShowState, setInnerShowState] = useState(show);

  return (
    <div className={`layer layer-video ${innerShowState ? "on" : ""}`}>
      <div className="middle-wrap">
        <div className="middle">
          <div className="content">
            <div className="title">{title}</div>
            <div className="video-area">
              <iframe
                width="760"
                height="428"
                src={`https://www.youtube.com/embed/${src}?autoplay=1`}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ border: 0 }}
                title={title}
              />
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setInnerShowState(false);
                close();
              }}
              style={{
                background: `url(${닫기_아이콘.src}) no-repeat center center`,
              }}
            >
              닫기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const 비디오_Desktop = ({
  src,
  title,
  children,
}: PropsWithChildren<VideoProps>) => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <CSR fallback={children}>
      <button onClick={() => setShowVideo(true)} style={{position:"relative"}}>{children}</button>
      {showVideo ? (
        <포탈>
          <비디오_모달_Desktop
            title={title}
            src={src}
            show={showVideo}
            close={() => setShowVideo(false)}
          />
        </포탈>
      ) : (
        <></>
      )}
    </CSR>
  );
};
