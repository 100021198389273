"use client";
import Link from "next/link";
import Slider, { type Settings } from "react-slick";
import NextIcon from "/public/assets/pc/images/icon/icon_right2.webp";
import PrevIcon from "/public/assets/pc/images/icon/icon_left2.webp";
import 화살표_아이콘 from "/public/assets/pc/images/icon/icon_move_link1.webp";
import { type MouseEventHandler, useState } from "react";
import {
  type 마켓봄_업데이트_소식_카드_정보,
  마켓봄_업데이트_소식_카드_정보_목록,
} from "./데이터/마켓봄_업데이트_소식";
import { useTracker } from "./util/Tracker";

const 마켓봄_업데이트_소식_카드 = ({
  제목,
  날짜,
  설명,
  링크,
}: 마켓봄_업데이트_소식_카드_정보) => {
  const tracker = useTracker();

  return (
    <Link
      href={링크}
      style={{
        background: `url(${화살표_아이콘.src})  no-repeat left 40px bottom 35px #fff`,
      }}
      target="_blank"
      onClick={() =>
        tracker.trackingClick({ depth1: "HOME", depth2: "업데이트" })
      }
    >
      <strong className="title">{제목}</strong>
      <span className="date">{날짜}</span>
      <span className="desc" style={{ whiteSpace: "pre" }}>
        {설명}
      </span>
    </Link>
  );
};

const Previous = ({
  onClick,
}: {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <button
      className="slick-prev slick-arrow"
      aria-label="Previous"
      type="button"
      style={{
        background: `url(${PrevIcon.src}) no-repeat center center rgba(255,255,255,.4)`,
      }}
      onClick={onClick}
    >
      Previous
    </button>
  );
};
const Next = ({
  onClick,
}: {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <button
      className="slick-next slick-arrow"
      aria-label="Next"
      type="button"
      style={{
        background: `url(${NextIcon.src}) no-repeat center center rgba(255,255,255,.4)`,
      }}
      onClick={onClick}
    >
      Next
    </button>
  );
};

export const 마켓봄_업데이트_소식 = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <Next />,
    prevArrow: <Previous />,
    afterChange: (index) => {
      setCurrentPage(index + 1);
    },
  };

  return (
    <div className="section section8">
      <div className="inner">
        <h3>마켓봄 업데이트와 주요 소식이에요.</h3>
        <div className="slide-wrap">
          <Slider className="notice-wrap" {...settings}>
            {마켓봄_업데이트_소식_카드_정보_목록.map(([카드1, 카드2], i) => (
              <div className="item" key={`업데이트_정보_${i}`}>
                <마켓봄_업데이트_소식_카드 {...카드1} />
                <마켓봄_업데이트_소식_카드 {...카드2} />
              </div>
            ))}
          </Slider>
          <div className="slick-nav">
            <span className="pager open-sans">
              <em className="current">{currentPage}</em> /
              <em className="total">
                {마켓봄_업데이트_소식_카드_정보_목록.length}
              </em>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
