import Image from "next/image";
import 섹션7_이미지2 from "/public/assets/pc/images/pic/img_section6_2.webp";
import 섹션7_이미지3 from "/public/assets/pc/images/pic/img_section6_3.webp";
import 섹션7_이미지4 from "/public/assets/pc/images/pic/img_section6_4.png";
import QuoteImage from "/public/assets/pc/images/pic/img_quotes1.webp";

export const 랜딩_섹션7_Desktop = () => {
  return (
    <div className="section section7">
      <div className="inner">
        <h3>
          쉽고 간편한 마켓봄을 사용하여
          <br />
          이런 효과를 보셨어요~!
        </h3>
        <ul>
          <li className="type1">
            <div
              className="pic"
              style={{
                background: `url(${섹션7_이미지3.src}) no-repeat center center`,
              }}
            >
              <dl>
                <dt>다봄</dt>
                <dd>종합유통사</dd>
              </dl>
            </div>
            <div className="text">
              <Image src={QuoteImage} alt="quote" />
              <dl>
                <dt>
                  거래처에 <span className="fc1">미수금 관리</span>가
                  <br />
                  편해졌어요.
                </dt>
                <dd>
                  기존에는 주문과 결제를 따로 진행하다 보니<br />
                  늦게 결제하거나 잘못 결제해서 생기는<br />
                  미수금이 엄청나게 많았는데,<br />
                  즉시 결제 가능한 마켓봄 결제수단 도입 후<br />
                  미수금 관리가 편해졌습니다.
                </dd>
              </dl>
            </div>
          </li>
          <li className="type2">
            <div
              className="pic"
              style={{
                background: `url(${섹션7_이미지4.src}) no-repeat center center`,
                backgroundSize: "370px 240px",
              }}
            >
              <dl>
                <dt>카페일분</dt>
                <dd>프랜차이즈 본사</dd>
              </dl>
            </div>
            <div className="text">
              <Image src={QuoteImage} alt="quote" />
              <dl>
                <dt>
                  120호 돌파 빠른 성장은<br />
                  <span className="fc1">관리 효율화 </span>
                   덕분이죠.
                </dt>
                <dd>
                  가맹점주님들은 편하게 발주하시고,<br />
                  본사는 선결제로 미수금, 자금 흐름 걱정 없이 <br />
                  효율적으로 사업에만 집중할 수 있어서<br />
                  빠른 성장이 가능했어요.<br />
                  저희 경쟁사들은 몰랐으면 좋겠어요.<br />
                </dd>
              </dl>
            </div>
          </li>
          <li className="type3">
            <div
              className="pic"
              style={{
                background: `url(${섹션7_이미지2.src}) no-repeat center center`,
              }}
            >
              <dl>
                <dt>라인물류</dt>
                <dd>물류대행사</dd>
              </dl>
            </div>
            <div className="text">
              <Image src={QuoteImage} alt="quote" />
              <dl>
                <dt>
                  여러 프랜차이즈의
                  <span className="fc1"> 통합 관리</span>가
                  <br />
                  가능해졌어요.
                </dt>
                <dd>
                  기존 이용하던 프로그램은<br />
                  등록한 상품이 모든 가맹점에 노출되어<br />
                  여러 프랜차이즈 동시 관리가 어려웠습니다.<br />
                  마켓봄을 이용한 후로 전산 비용을<br />
                  1/10로 줄일 수 있었습니다.<br />
                </dd>
              </dl>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
