import 섹션6_이미지1 from "/public/assets/pc/images/pic/img_section5_bg1.webp";
import 섹션6_이미지2 from "/public/assets/pc/images/pic/img_section5.webp";
import { useCountAnimation } from "./IncreamentNumber";
import useOnScreen from "./util/useOnScreen";
import { useEffect, useRef, useState } from "react";
import { 누적이용자수 } from "./데이터/누적가입자수";
import { useTracker } from "./util/Tracker";

export const 랜딩_섹션6_Desktop_Skeleton = () => {
  return (
    <div
      className="section section6"
      style={{
        background: `url(${섹션6_이미지1.src}) repeat-x center bottom`,
      }}
    >
      <div
        className="inner"
        style={{
          background: `url(${섹션6_이미지2.src}) no-repeat center bottom`,
        }}
      >
        <h3>
          많은 프랜차이즈, 유통사들이
          <br />
          마켓봄을 믿고 사용 중입니다.
        </h3>
        <div className="col-wrap">
          <div className="col">
            <h4>누적 유통사</h4>
            <div className="open-sans counter" data-count="17909">
              <strong>{0}</strong>
            </div>
          </div>
          <div className="col">
            <h4>
              누적 거래처 <span>(식당, 가맹점)</span>
            </h4>
            <div className="open-sans counter" data-count="173150">
              <strong>{0}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const 랜딩_섹션6_Desktop = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [canStart, setCanStart] = useState(false);
  const firedOnce = useRef(false);
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    setCanStart(isVisible);
    firedOnce.current = true;
  }, [isVisible]);

  const 누적유통사 = useCountAnimation({
    startNumber: 0,
    endNumber: 누적이용자수.누적유통사,
    durationMS: 3000,
    canStart: firedOnce.current || canStart,
  });
  const 누적거래처 = useCountAnimation({
    startNumber: 0,
    endNumber: 누적이용자수.누적거래처,
    durationMS: 3000,
    canStart: firedOnce.current || canStart,
  });
  const [isVisibles, setIsVisibles] = useState(false);
  const [fireOnce, setFireOnce] = useState(false);
  const tracker = useTracker();

  useEffect(() => {
    const 문의listener = () => {
      setIsVisibles(window.scrollY > (ref.current?.offsetTop ?? 99999));
    };

    window.addEventListener("scroll", 문의listener);
    return () => {
      window.removeEventListener("scroll", 문의listener);
    };
  }, [ref, isVisibles]);

  useEffect(() => {
    if (!fireOnce && isVisible) {
      tracker.trackingScroll({
        depth1: "HOME",
        depth2: "누적회원수",
      });
      setFireOnce(true);
    }
  }, [isVisible, fireOnce, tracker]);
  return (
    <div
      className="section section6"
      style={{
        background: `url(${섹션6_이미지1.src}) repeat-x center bottom`,
      }}
      ref={ref}
    >
      <div
        className="inner"
        style={{
          background: `url(${섹션6_이미지2.src}) no-repeat center bottom`,
        }}
      >
        <div
          style={{
            marginBottom: "6px",
            color: "#333",
            textAlign: "center",
            fontSize: "24px",
            fontStyle: " normal",
            fontWeight: " 400",
            letterSpacing: "-1.2px",
          }}
        >
          많은 프랜차이즈, 유통사들이 믿고 사용하는
        </div>
        <h3>
          <div>식자재 수발주 마켓봄</div>
        </h3>
        <div className="col-wrap">
          <div className="col">
            <h4>누적 유통사</h4>
            <div className="open-sans counter" data-count="17909">
              <strong>{누적유통사}</strong>
            </div>
          </div>
          <div className="col">
            <h4>
              누적 거래처 <span>(식당, 가맹점)</span>
            </h4>
            <div className="open-sans counter" data-count="173150">
              <strong>{누적거래처}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
