"use client";
import { type PropsWithChildren, useState } from "react";
import { CSR } from "./CSR";
import { 포탈 } from "./포탈";
import 닫기_아이콘 from "/public/assets/mobile/images/btn/btn_close2_b.svg";

type VideoModalProps = {
  title: string;
  src: string;
  show: boolean;
  close: () => void;
};
export type VideoProps = {
  title: string;
  src: string;
};

const 비디오_모달_Mobile = ({ src, title, close, show }: VideoModalProps) => {
  const [innerShowState, setInnerShowState] = useState(show);
  return (
    <div
      className={`layer layer-video ${innerShowState ? "on" : ""}`}
      style={{
        opacity: innerShowState ? 1 : 0,
      }}
    >
      <div className="middle-wrap">
        <div className="middle">
          <div className="content">
            <div className="title">{title}</div>
            <div className="video-area">
              <iframe
                width="100%"
                height="207px"
                src={`https://www.youtube.com/embed/${src}`}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ border: 0 }}
                title={title}
              />
            </div>
            <button
              type="button"
              className="btn-close"
              style={{
                position: "absolute",
                right: "14px",
                width: "24px",
                height: "24px",
                background: `url(${닫기_아이콘.src}) no-repeat center center`,
                zIndex: 1,
              }}
              onClick={() => {
                setInnerShowState(false);
                close();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const 비디오_Mobile = ({
  src,
  title,
  children,
}: PropsWithChildren<VideoProps>) => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <CSR>
      <button onClick={() => setShowVideo(true)}>{children}</button>
      {showVideo ? (
        <포탈>
          <비디오_모달_Mobile
            title={title}
            src={src}
            show={showVideo}
            close={() =>
              setTimeout(() => {
                setShowVideo(false);
              }, 300)
            }
          />
        </포탈>
      ) : (
        <></>
      )}
    </CSR>
  );
};
