import { type MouseEventHandler, useState, useEffect, useRef, } from "react";
import Image from "next/image";
import { 비디오_Desktop } from "./비디오_Desktop";
import { useTracker } from "./util/Tracker";
import { 링크 } from "./데이터/링크";

import Slider, { type Settings } from "react-slick";
import NextIcon from "/public/assets/pc/images/icon/icon_right5.png";
import PrevIcon from "/public/assets/pc/images/icon/icon_left5.png";
import PlayIcon from "/public/assets/pc/images/icon/btn_play.png";
import PlayIconOn from "/public/assets/pc/images/icon/btn_play_on.png";

import 유투브0 from "/public/assets/pc/images/pic/youtube_thumbnail0.png";
import 유투브1 from "/public/assets/pc/images/pic/youtube_thumbnail1.png";
import 유투브2 from "/public/assets/pc/images/pic/youtube_thumbnail2.png";
import 유투브3 from "/public/assets/pc/images/pic/youtube_thumbnail3.png";
import 유투브4 from "/public/assets/pc/images/pic/youtube_thumbnail4.png";

import styles from "./랜딩_섹션2_Desktop.module.css";

export const 랜딩_섹션2_Desktop = () => {
  const tracker = useTracker();
  const 유투브_관련_데이터 = [{
    메인화면:유투브0,
    페이징썸네일:유투브0,
    영상링크:링크.랜딩_영상[0],
    title:"마켓봄 성공 사례 인터뷰",
    trackerDepth1:"HOME",
    trackerDepth2:"마켓봄 소개 영상",
    trackerDepth2_paging:"영상 선택",
    trackerDepth3:"플레이1",
    trackerDepth3_paging:"1번",
  },
  {
    메인화면:유투브1,
    페이징썸네일:유투브1,
    영상링크:링크.랜딩_영상[1],
    title:"마켓봄 성공 사례 인터뷰",
    trackerDepth1:"HOME",
    trackerDepth2:"마켓봄 소개 영상",
    trackerDepth2_paging:"영상 선택",
    trackerDepth3:"플레이2",
    trackerDepth3_paging:"2번",
  },
  {
    메인화면:유투브2,
    페이징썸네일:유투브2,
    영상링크:링크.랜딩_영상[2],
    title:"마켓봄 성공 사례 인터뷰",
    trackerDepth1:"HOME",
    trackerDepth2:"마켓봄 소개 영상",
    trackerDepth2_paging:"영상 선택",
    trackerDepth3:"플레이3",
    trackerDepth3_paging:"3번",
  },
  {
    메인화면:유투브3,
    페이징썸네일:유투브3,
    영상링크:링크.랜딩_영상[3],
    title:"마켓봄 성공 사례 인터뷰",
    trackerDepth1:"HOME",
    trackerDepth2:"마켓봄 소개 영상",
    trackerDepth2_paging:"영상 선택",
    trackerDepth3:"플레이4",
    trackerDepth3_paging:"4번",
  },
  {
    메인화면:유투브4,
    페이징썸네일:유투브4,
    영상링크:링크.랜딩_영상[4],
    title:"마켓봄 성공 사례 인터뷰",
    trackerDepth1:"HOME",
    trackerDepth2:"마켓봄 소개 영상",
    trackerDepth2_paging:"영상 선택",
    trackerDepth3:"플레이5",
    trackerDepth3_paging:"5번",
  }];
  // State to track hover
  const [isHovering, setIsHovering] = useState(false);

  // Event handlers
  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);
  const Previous = ({
    onClick,
  }: {
    onClick?: MouseEventHandler<HTMLButtonElement>;
  }) => {
    return (
      <button
        className={`slick-prev slick-arrow ${styles["arrow-content-none"]}`}
        aria-label="Previous"
        type="button"
        style={{
          top: "40%",
          left: "0",
          width: "23px",
          height: "46px",
          background: `url(${PrevIcon.src}) no-repeat center center rgba(255,255,255,.4)`,
          backgroundSize: "100%",
          zIndex:"100",
        }}
        onClick={onClick}
      >
        Previous
      </button>
    );
  };
  const Next = ({
    onClick,
  }: {
    onClick?: MouseEventHandler<HTMLButtonElement>;
  }) => {
    return (
      <button
        className={`slick-next slick-arrow ${styles["arrow-content-none"]}`}
        aria-label="Next"
        type="button"
        style={{
          top: "40%",
          right: "0",
          width: "23px",
          height: "46px",
          background: `url(${NextIcon.src}) no-repeat center center rgba(255,255,255,.4)`,
          backgroundSize: "100%",
          zIndex:"100",
        }}
        onClick={onClick}
      >
        Next
      </button>
    );
  };
  const settings: Settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    adaptiveHeight: true,
    pauseOnHover: true,
    nextArrow: <Next />,
    prevArrow: <Previous />,
    appendDots: (dots: never) => (
      <div
        style={{
          bottom: "-141px",
        }}
      >
        <ul className={styles["comstom_paging"]}> {dots} </ul>
      </div>
    ),
    dotsClass: `${styles["dots_custom"]}`,
    customPaging: function(i) {
      return (
        <a>
          <Image
            src={유투브_관련_데이터[i]?.페이징썸네일 ?? 유투브0}
            style={{overflow:"hidden",borderRadius: "10px",boxShadow: "5px 5px 6px 0px rgba(38, 29, 72, 0.30)"}}
            alt=""
            width={200}
            height={113}
            fetchPriority="high"
            priority
            loading={"eager"}
            onClick={() => {
              tracker.trackingClick({
                depth1: 유투브_관련_데이터[i]?.trackerDepth1,
                depth2: 유투브_관련_데이터[i]?.trackerDepth2_paging,
                depth3: 유투브_관련_데이터[i]?.trackerDepth3_paging,
              });
            }}
          />
        </a>
      );
    },
  };
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [fireOnce, setFireOnce] = useState(false); 

  useEffect(() => {
    const 동영상listener = () => {
      setIsVisible(window.scrollY > (ref.current?.offsetTop ?? 99999));
    };

    window.addEventListener("scroll", 동영상listener);
    return () => {
      window.removeEventListener("scroll", 동영상listener);
    };
  }, [ref, isVisible]);

  useEffect(() => {
    if (!fireOnce && isVisible) {
      tracker.trackingScroll({
        depth1: "HOME",
        depth2: "동영상",
      });
      setFireOnce(true);
    }
  }, [isVisible, fireOnce, tracker]);

  return (
    <div className="section section2" id="section2" ref={ref}>
      <div className="inner" style={{ paddingBottom: "30px" }}>
        <dl>
          <dt>식자재 유통은 마켓봄</dt>
          <dd>
            이제 식자재 유통도 <strong className="bold">온라인 시대!</strong>
            <br />
            언제 어디서나 쉽고 편리한 마켓봄으로
            <br />
            <div className="highlight">
              <p>
                수발주 효율
                <span>
                  UP<em style={{ marginRight: "16px" }}>{"!"}</em>
                </span>
                매출도
                <span>
                  UP<em style={{ marginRight: "16px" }}>{"!"}</em>
                </span>
                미수금
                <span>
                  DOWN<em>{"!"}</em>
                </span>
              </p>
            </div>
          </dd>
        </dl>
        <div className="pic">
          <div className={styles["youtube-wrap"]}>
            <Slider {...settings}>
              {유투브_관련_데이터.map((item) => (
                <div className="item" key={item.title}>
                  {item.영상링크 && (
                    <비디오_Desktop title={item.title} src={item.영상링크 ?? 링크.랜딩_영상[0]}>
                      <Image style={{overflow:"hidden",borderRadius: "20px", boxShadow:"5px 5px 6px 0px rgba(38, 29, 72, 0.10)"}}
                        width={720}
                        height={405}
                        src={item.메인화면}
                        alt={item.title}
                        onClick={() => {
                          tracker.trackingClick({
                            depth1: item.trackerDepth1,
                            depth2: item.trackerDepth2,
                            depth3: item.trackerDepth3,
                          });
                        }}
                      />
                      <Image onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
                        style={{position:"absolute", top:"50%", left: "50%", translate:"-25% -25%"}}
                        width={100}
                        height={100}
                        alt="영상보기"
                        src={isHovering ? PlayIconOn : PlayIcon} 
                        onClick={() => {
                          tracker.trackingClick({
                            depth1: item.trackerDepth1,
                            depth2: item.trackerDepth2,
                            depth3: item.trackerDepth3,
                          });
                        }}
                      />
                    </비디오_Desktop>
                  )}
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};
