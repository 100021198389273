import { useRef, useState, useEffect } from "react";
import { useCountAnimation } from "./IncreamentNumber";
import useOnScreen from "./util/useOnScreen";
import { 누적이용자수 } from "./데이터/누적가입자수";
import 섹션6_이미지 from "/public/assets/mobile/images/pic/img_main_section5.webp";
import { useTracker } from "./util/Tracker";
export const 랜딩_섹션6_Mobile = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [canStart, setCanStart] = useState(false);
  const firedOnce = useRef(false);
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    setCanStart(isVisible);
    firedOnce.current = true;
  }, [isVisible]);

  const 누적유통사 = useCountAnimation({
    startNumber: 0,
    endNumber: 누적이용자수.누적유통사,
    durationMS: 3000,
    canStart: firedOnce.current || canStart,
  });
  const 누적거래처 = useCountAnimation({
    startNumber: 0,
    endNumber: 누적이용자수.누적거래처,
    durationMS: 3000,
    canStart: firedOnce.current || canStart,
  });
  
  const tracker = useTracker();

  const [isTracked, setIsTracked] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // 요소가 뷰포트에 들어오는 경우에만 트래킹
          if (entry.isIntersecting && !isTracked) {
            tracker.trackingScroll({
              depth1: "HOME",
              depth2: "누적회원수",
            });
            setIsTracked(true); // 요소가 한 번 트래킹되면 다시 트래킹되지 않도록 상태를 변경
          }
        });
      },
      {
        rootMargin: "0px", // 필요에 따라 rootMargin을 조정할 수 있습니다.
        threshold: 0.5, // 요소가 10% 보일 때 콜백이 실행되도록 threshold 조정
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [isTracked, tracker]); // 의존성 배열에 isTracked와 tracker를 추가

  return (
    <div
      className="section section6"
      ref={ref}
      style={{
        background: `url(${섹션6_이미지.src}) no-repeat center bottom`,
        backgroundSize: "100% auto",
      }}
    >
      <h3>
        많은 프랜차이즈, 유통사들이
        <br />
        마켓봄을 믿고 사용 중입니다.
      </h3>
      <dl>
        <dt>누적 유통사</dt>
        <dd className="counter" data-count="10577">
          <strong>{누적유통사}</strong>
        </dd>
      </dl>
      <dl>
        <dt>
          누적 거래처<span>(식당, 가맹점)</span>
        </dt>
        <dd className="counter" data-count="57577">
          <strong>{누적거래처}</strong>
        </dd>
      </dl>
    </div>
  );
};
