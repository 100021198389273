// import 랜딩_Mobile from "../components/랜딩_Mobile";
import * as 장치 from "../components/장치";
import type { GetServerSideProps } from "next";

import 랜딩_Desktop2 from "../components/랜딩_Desktop2";

import { LayoutSplitter } from "../layouts/LayoutSplitter";

import 랜딩_Mobile2 from "../components/랜딩_Mobile2";

type 랜딩Props = {
  장치: 장치.t;
};

const 랜딩 = ({ 장치 }: 랜딩Props) => {
  return (
    <LayoutSplitter
      장치={장치}
      pc={<랜딩_Desktop2 />}
      mobile={<랜딩_Mobile2 />}
    />
  );
};

export default 랜딩;

export const getServerSideProps: GetServerSideProps<랜딩Props> = async (
  ctx
) => {
  return {
    props: {
      장치: 장치.fromContext(ctx)
    }
  };
};
