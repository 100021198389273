export type 마켓봄_업데이트_소식_카드_정보 = {
  제목: string;
  날짜: `${string}. ${string}. ${string}`;
  설명: string;
  링크: string;
};
export type 마켓봄_업데이트_소식_정보 = {
  카드_정보_목록: [
    마켓봄_업데이트_소식_카드_정보,
    마켓봄_업데이트_소식_카드_정보
  ][];
};

export const 마켓봄_업데이트_소식_카드_정보_목록: [
  마켓봄_업데이트_소식_카드_정보,
  마켓봄_업데이트_소식_카드_정보
][] = [
  [
    {
      제목: "비대면바우처 3차 모집 시작",
      날짜: "2023. 08. 01",
      설명: "지원금 조기마감 예상!\n마켓봄 컨설턴트가 신청부터 쉽고 빠르게 도와드려요.",
      링크: "https://blog.naver.com/marketbom/223171667925",
    },
    {
      제목: "물류비는 오르고, 가맹 문의는 줄고",
      날짜: "2023. 07. 27",
      설명: "외식 프랜차이즈 본사의\n수익구조 악화 3대 원인을 파악해 보세요.",
      링크: "https://blog.naver.com/marketbom/223167646098",
    },
  ],
  [
    {
      제목: "재고관리, 할 때 마다 귀찮으세요?",
      날짜: "2023. 07. 20",
      설명: "재고관리 시스템을 갖추면\n차별화된 사업 경쟁력으로 이어집니다!",
      링크: "https://blog.naver.com/marketbom/223161302742",
    },
    {
      제목: "식자재 유통 관리가 고민이세요?",
      날짜: "2023. 07. 19",
      설명: "유통 현장에서 쓰기 쉬운 수발주 ERP!\n식자재 유통에 특화된 마켓봄 ERP를 만나보세요.",
      링크: "https://blog.naver.com/marketbom/223160758896",
    },
  ],
];
