import Image from "next/image";
import GooglePlay from "/public/assets/mobile/images/pic/img_googleplay1.webp";
import AppStore from "/public/assets/mobile/images/pic/img_appstore1.webp";
import { useTracker } from "./util/Tracker";

import { 링크 } from "./데이터/링크";

export const 랜딩_섹션5_Mobile = () => {
  const tracker = useTracker();

  return (
    <div className="section section5">
      <h3>앱 다운로드</h3>
      <div className="white-box">
        <div className="row">
          <h4>거래처(식당)용 발주 전용 앱 다운로드</h4>
          <a
            href={링크.거래채앱_구글플레이}
            className="btn-googleplay"
            onClick={() =>
              tracker.trackingClick({
                depth1: "HOME",
                depth2: "구글플레이거래처앱",
              })
            }
          >
            <Image src={GooglePlay} alt="Googleplay" width={131} />
          </a>
          <a
            href={링크.거래채앱_앱스토어}
            className="btn-appstore"
            onClick={() =>
              tracker.trackingClick({
                depth1: "HOME",
                depth2: "앱스토어거래처앱",
              })
            }
          >
            <Image src={AppStore} alt="App Store" width={135} />
          </a>
        </div>
        <div className="row">
          <h4>유통사용 전용 앱 다운로드</h4>
          <a
            href={링크.유통사앱_구글플레이}
            className="btn-googleplay"
            onClick={() =>
              tracker.trackingClick({
                depth1: "HOME",
                depth2: "구글플레이유통사앱",
              })
            }
          >
            <Image src={GooglePlay} alt="Googleplay" width={131} />
          </a>
        </div>
      </div>
    </div>
  );
};
