import { 헤더_DesktopAB } from "./헤더_DesktopAB";
import { 마켓봄_업데이트_소식 } from "./마켓봄_업데이트_소식_Desktop";
import { 랜딩_섹션2_Desktop } from "./랜딩_섹션2_Desktop";
import { 랜딩_섹션3_Desktop } from "./랜딩_섹션3_Desktop";
import { 랜딩_섹션4_Desktop } from "./랜딩_섹션4_Desktop";
import { 랜딩_섹션5_Desktop } from "./랜딩_섹션5_Desktop";
import { 랜딩_섹션6_Desktop, 랜딩_섹션6_Desktop_Skeleton } from "./랜딩_섹션6_Desktop";
import { 랜딩_섹션7_Desktop } from "./랜딩_섹션7_Desktop";
import { 랜딩_섹션9_Desktop } from "./랜딩_섹션9_Desktop";
import { 푸터_Desktop } from "./푸터_Desktop";
import { CSR } from "./CSR";
import { CTA_간편가입_Desktop } from "./CTA_간편가입_Desktop";
import { 랜딩_섹션1_Desktop2 } from "./랜딩_섹션1_Desktop2";

const 랜딩_PC = () => {
  return (
    <>
      <div id="wrap">
        <헤더_DesktopAB />
        <div id="container" className="main">
          <랜딩_섹션1_Desktop2 />
          <랜딩_섹션2_Desktop />
          <랜딩_섹션3_Desktop />
          <랜딩_섹션4_Desktop />
          <랜딩_섹션5_Desktop />
          <CSR fallback={<랜딩_섹션6_Desktop_Skeleton/>}>
            <랜딩_섹션6_Desktop />
          </CSR>
          <랜딩_섹션7_Desktop />
          <마켓봄_업데이트_소식 />
          <랜딩_섹션9_Desktop />
        </div>
        <푸터_Desktop />
        <CTA_간편가입_Desktop />
      </div>
    </>
  );
};
export default 랜딩_PC;
